@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.border {
  width: inherit;
  display: flex;
  justify-content: center;
  background: var(--cst-footer-background);
  -webkit-border-top-left-radius: var(--cornerradius12) !important;
  -webkit-border-top-right-radius: var(--cornerradius12) !important;
  border-top-left-radius: var(--cornerradius12) !important;
  border-top-right-radius: var(--cornerradius12) !important;
  border-start-start-radius: var(--cornerradius12) !important;
  border-start-end-radius: var(--cornerradius12) !important;
  @include min-428-break {
    -webkit-border-top-left-radius: var(--cornerradius14) !important;
    -webkit-border-top-right-radius: var(--cornerradius14) !important;
    border-top-left-radius: var(--cornerradius14) !important;
    border-top-right-radius: var(--cornerradius14) !important;
    border-start-start-radius: var(--cornerradius14) !important;
    border-start-end-radius: var(--cornerradius14) !important;
  }

  @include min-744-break {
    -webkit-border-top-left-radius: var(--cornerradius16) !important;
    -webkit-border-top-right-radius: var(--cornerradius16) !important;
    border-top-left-radius: var(--cornerradius16) !important;
    border-top-right-radius: var(--cornerradius16) !important;
    border-start-start-radius: var(--cornerradius16) !important;
    border-start-end-radius: var(--cornerradius16) !important;
  }
}

.container {
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100%;
  min-width: 320px;
  background: var(--global-grey300);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 976px;

    gap: 14px;

    padding: 12px 14px;

    @include min-428-break {
      padding: 14px;
      gap: 18px;
    }

    @include min-744-break {
      padding: 14px 28px;
    }

    .social-links {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .social-link {
        @include fixedSize(42px, 38px);

        @include min-428-break {
          @include fixedSize(58px, 46px);
        }

        @include min-744-break {
          @include fixedSize(102px, 50px);
        }
      }
    }

    .deskr,
    .rights {
      @include typography-xs;
      @include weight-medium;

      color: var(--cst-footer-desripction);

      @include min-428-break {
        @include typography-base;
      }
    }

    .accordions {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 6px;
      @include min-428-break {
        gap: 10px;
      }
    }

    .agreements {
      display: flex;
      gap: 4px;
      max-width: inherit;
      justify-content: space-between;

      .agreement-link {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 8px;
        @include fixedSize(calc(50% - 2px), 38px);
        @include buttonSizes('38', '46', '50', '50', '50', '50');

        @include min-428-break {
          gap: 10px;
          @include fixedSize(calc(50% - 5px), 46px);
        }

        @include min-744-break {
          @include fixedSize(calc(50% - 5px), 50px);
        }

        > .social-icon {
          @include singleFixedSize(18px);

          @include min-428-break {
            @include singleFixedSize(20px);
          }

          @include min-744-break {
            @include singleFixedSize(22px);
          }
        }
      }
    }
  }
}

.social-link {
  @include fixedSize(42px, 38px);

  @include min-428-break {
    @include fixedSize(58px, 46px);
  }

  @include min-744-break {
    @include fixedSize(102px, 50px);
  }
}

.social-icon {
  color: var(--global-purple500);

  @include singleFixedSize(14px);

  @include min-428-break {
    @include singleFixedSize(20px);
  }

  @include min-744-break {
    @include singleFixedSize(22px);
  }
}

.links-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 4px;
  border-radius: var(--cornerradius10);
  width: 100%;
  box-shadow: var(--elevation-1);
  outline: 2px solid var(--cst-drop-listsort-outlineopen-default);
  outline-offset: -2px;
  background: var(--cst-drop-listsort-backgroundopen-default);
  padding: 2px;

  .link-container {
    display: flex;
    padding: 11px 10px;
    border-radius: var(--cornerradius10);

    p[id='list-item-title'] {
      @include typography-s;
      @include weight-medium;
      color: var(--cst-dropitem-text-default);

      @include min-428-break {
        @include typography-m;
      }
    }

    @include min-428-break {
      padding: 15px 10px;
    }
  }
}
