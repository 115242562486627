@use '@queries' as *;
@use '@fixed-size' as *;

.background-wrapper {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  @include fixedSize(100%, 180px);
  background-image: url('/assets/legal/illustration-legal.webp');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @include min-428-break {
    @include fixedSize(100%, 212px);
  }

  @include min-744-break {
    @include fixedSize(100%, 240px);
  }
}
