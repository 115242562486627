@use '@queries' as *;
@use '@fixed-size' as *;

.first-section {
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.item {
  z-index: 2;
  position: relative;
  right: -32px;
  @include singleFixedSize(90px);

  @include min-428-break {
    right: -36px;
    top: -10px;
    @include singleFixedSize(124px);
  }

  @include min-744-break {
    right: -16px;
    top: -8px;
  }
}
