@use '@queries' as *;
@use '@content' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 110px;

  @include min-428-break {
    padding-top: 130px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  @include mainContentStyles;
  @include mainContainerStyles;
  width: 100%;
  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }
  @include min-744-break {
    padding: 14px 28px;
  }
}

.first-section {
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
